angular.module('fingerink')
    .service('viewSignatureModal', function ($uibModal) {
        this.modalInstance = null;
        this.openModal = function (firma) {
            this.modalInstance =   $uibModal.open({
                templateUrl: 'modals/viewSignatureModal.tpl.html',
                controller: 'viewSignatureModalCtrl',
                controllerAs: 'controller',
                animation: false,
                openedClass:'viewSignatureModal',
                resolve: {
                    firma: function () {
                        return firma;
                    }
                }
            });
            
            return this.modalInstance;
        };

    })
    .controller('viewSignatureModalCtrl', function viewSignatureModalCtrl($scope, session, signatureService, fingerinkDocumentImage, $uibModalInstance, firma) {
        var that = this;
        that.firma = firma;
        
        signatureService.retrieveFirma(firma.id).then(function (response) {
            that.sign = response.data;
            that.firma = that.sign;
            that.firmantesId = [];
            that.pageImages = fingerinkDocumentImage.getImages(that.sign);
            for(var k =0 ;k<that.firma.signers.length;k++){
                that.firmantesId[that.firma.signers[k].id] = that.firma.signers[k];
            }
        });

        that.close = function () {
            $uibModalInstance.close(that.widgets);
        };
    });
